<template>
  <div class="wrapper">
    <Section>
      <HeadingBlock :data="pageContent" />
    </Section>
    <Section v-if="isLoaded">
      <Form
        :data="form"
        :id="form.id"
        form="contact"
        @changeOption="changeOption"
      />
    </Section>
  </div>
</template>

<script>
import Form from "@/components/Forms/Form.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import Section from "@/components/Section/Section.vue";
import FormConfiguration from "@/data/FormsDev.json";

export default {
  name: "Contact",
  components: {
    Form,
    HeadingBlock,
    Section,
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
      form: {},
      dealers: [],
      isLoaded: false,
    };
  },
  mounted() {
    this.form = FormConfiguration.contact;
    this.$store.state.allInstallations.forEach((element) => {
      if (element.designation != undefined || element.objectId != undefined) {
        this.dealers.push({
          label: element.designation + " - " + element.postalCodeDesig,
          value: element.objectId,
        });
      }
    });
    this.form.fields.find((el) => el.name == "oid").options = this.dealers;
    this.isLoaded = true;
  },
  methods: {
    changeOption(e) {
      this.dealerDetail.forEach((element) => {
        if (element.objectId == e) {
          element.contacts[0].contactPersons[0].contactList.forEach((el) => {
            if (el.designation == "E-mail") {
              let customLead = document.getElementById(
                "contact-form-custom_lead"
              );
              customLead.value = el.value;
            }
          });
        }
      });
    },
  },
  computed: {
    dealerDetail() {
      return this.$store.state.allInstallations;
    },
    banner() {
      return this.$store.state.contact.image_banner;
    },
    pageContent() {
      return {
        blockTitle: this.$store.state.contact.title_block,
        blockText: this.$store.state.contact.text_block,
      };
    },
    seo() {
      return this.$store.state.homepage.seo.general;
    },
  },
};
</script>
